<script setup lang="ts">
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core'
import type {FilterAreaState} from "~/types";

const props = defineProps({
	modelValue: {
		type: Object as PropType<FilterAreaState>,
		required: true,
	},
	autofocus: {
		type: Boolean,
		default: true
	}
})

const emits = defineEmits([
	'update:modelValue'
])

const { t: tg } = useI18n({
	useScope: 'global'
})

const breakpoints = useBreakpoints(breakpointsTailwind)
const isDesktop = breakpoints.isGreaterOrEqual('md')

const inputFocus = ref<[]>([])

const formHelper = useFormHelper()

const localValue = computed({
	get: () => props.modelValue as FilterAreaState,
	set: (value) => emits('update:modelValue', value)
})

const onCheckFrom = (value: any) => {
	if (!formHelper.isNumber(value.from) || !formHelper.isNumber(value.to)) {
		return
	}
	if (value.from > value.to) {
		value.from = value.to
	}
}

const onCheckTo = (value: any) => {
	if (!formHelper.isNumber(value.from) || !formHelper.isNumber(value.to)) {
		return
	}
	if (value.to < value.from) {
		value.to = value.from
	}
}

const toRef = (el, index) => {
	inputFocus.value[index] = el
}

const items = [
	{ name: 'general', label: tg('filter.area.general'), unit: tg('unit.square_meter') },
	{ name: 'kitchen', label: tg('filter.area.kitchen'), unit: tg('unit.square_meter') },
	{ name: 'living', label: tg('filter.area.living'), unit: tg('unit.square_meter') },
]

onMounted(() => {
	if (props.autofocus) {
		setTimeout(() => {
			inputFocus.value[0].input.focus()
		}, isDesktop ? 0 : 300)
	}
})
</script>

<template>
	<div
		v-bind="$attrs"
		class="flex gap-2 items-center"
	>
		<div class="flex flex-col gap-2 w-full md:w-auto">
			<div
				v-for="(item, index) in items"
				:key="index"
				class="flex gap-2 items-center"
			>
				<span class="truncate text-sm w-36 md:w-16">
					{{ item.label }}
				</span>
				<UInput
					:ref="el => toRef(el, index)"
					v-model="localValue[item.name].from"
					type="number"
					:placeholder="tg('filter.placeholder.from')"
					class="w-full md:w-24"
					@blur="onCheckFrom(localValue[item.name])"
				>
					<template #trailing>
						<span class="text-sm">
							{{ item.unit }}
						</span>
					</template>
				</UInput>
				<UInput
					v-model="localValue[item.name].to"
					type="number"
					:placeholder="tg('filter.placeholder.to')"
					class="w-full md:w-24"
					@blur="onCheckTo(localValue[item.name])"
				>
					<template #trailing>
						<span class="text-sm">
							{{ item.unit }}
						</span>
					</template>
				</UInput>
			</div>
			<div v-if="false" class="flex gap-2 items-center">
				<span class="truncate text-sm w-36 md:w-16">
					{{ tg('filter.field.plot') }}
				</span>
				<UInput
					v-model="localValue.plot.from"
					type="number"
					:placeholder="tg('filter.placeholder.from')"
					class="w-full md:w-24"
					@blur="onCheckFrom(localValue.plot)"
				>
					<template #trailing>
						<span class="text-sm">
							{{ tg('unit.acres') }}
						</span>
					</template>
				</UInput>
				<UInput
					v-model="localValue.plot.to"
					type="number"
					:placeholder="tg('filter.placeholder.to')"
					class="w-full md:w-24"
					@blur="onCheckTo(localValue.plot)"
				>
					<template #trailing>
						<span class="text-sm">
							{{ tg('unit.acres') }}
						</span>
					</template>
				</UInput>
			</div>
		</div>
	</div>
</template>