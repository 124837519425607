<script setup lang="ts">
import type { PropType } from 'vue'

const props = defineProps({
	modelValue: {
		type: undefined as unknown as PropType<string | undefined>,
		required: true
	},
	placeholder: {
		type: String,
		required: true
	}
})

const emits = defineEmits([
	'update:modelValue'
])

const localValue = computed({
	get: () => props.modelValue,
	set: (value) => emits('update:modelValue', value)
})

const { t } = useI18n({
	useScope: 'local'
})

const filterStore = useFilterStore()

const loading = ref(false)
const selected = ref(undefined)

const haValue = computed(() => selected.value)

const search = async (q: string) => {
	if (q?.length < 2 || !filterStore.onSearch) {
		return []
	}
	
	loading.value = true
	
	const result = await filterStore.onSearch({
		...filterStore.state,
		...{ search: q }
	})
	
	loading.value = false
	
	const data = result?.data ?? []

	const groups = []
	if (Object.keys(data).length > 0) {
		Object.keys(data).forEach((key) => {
			if (data[key] && data[key].length > 0) {
				groups.push({
					key: key,
					label: t('group.' + key),
					items: data[key].map((item: any) => {
						return {
							id: item,
							title: item,
							group: key,
						}
					}),
				})
			}
		})
	}

	return groups
}

const onSelectLocal = async (val: any) => {
	if (val?.id) {
		localValue.value = {[val.group]: val.id}
	}
	else {
		localValue.value = undefined
	}

	if (filterStore.onSelect) {
		await filterStore.onSelect(val)
	}
}

const onClear = () => {
	localValue.value = undefined
	selected.value = undefined
}

if (localValue.value) {
	Object.keys(localValue.value).forEach((key) => {
		selected.value = localValue.value[key]
	})
}
</script>

<template>
	<UiInputMenu
		v-model="selected"
		:search="search"
		:loading="loading"
		:placeholder="placeholder"
		:nullable="true"
		class="flex-1"
		:ui-menu="{
			width: '!min-w-0',
			label: 'block truncate-none',
		}"
		trailing-icon="i-heroicons-magnifying-glass-20-solid"
		loading-icon="i-svg-spinners-ring-resize"
		option-attribute="title"
		by="id"
		trailing
		@change="onSelectLocal"
	>
		<template #group="{ group }">
			<li class="bg-white sticky top-0 z-50 text-sm font-semibold px-1.5 pt-2 pb-1">
				{{ group.label }}
			</li>
		</template>
		<template #option="{ option }">
			{{ option.title }}
		</template>
		<template #trailing>
			<UIcon
				v-if="loading"
				name="i-svg-spinners-ring-resize"
				class="flex-shrink-0 text-gray-400 w-5 h-5"
				dynamic
			>
			</UIcon>
			<UIcon
				v-else-if="!selected"
				name="i-heroicons-magnifying-glass-20-solid"
				class="flex-shrink-0 text-gray-400 w-5 h-5"
			>
			</UIcon>
			<UIcon
				v-else
				name="i-heroicons-x-mark"
				class="flex-shrink-0 text-gray-400 w-5 h-5"
				@click="onClear"
			/>
		</template>
		<template #option-empty="{ query }">
			<template v-if="loading">
				{{ t('loading') }}
			</template>
			<template v-else-if="query?.length < 3">
				{{ t('empty_query') }}
			</template>
			<template v-else>
        {{ t('not_found', { query }) }}
      </template>
		</template>
		<template #empty>
			{{ t('empty_query') }}
		</template>
	</UiInputMenu>
</template>

<i18n lang="json">
{
	"ru": {
		"loading": "Идет поиск...",
		"empty_query": "Введите еще несколько символов",
		"not_found": "По запросу «{query}» ничего не найдено",
		"group": {
			"buildings": "Жилой комплекс",
			"districts": "Округ",
			"areas": "Район",
			"streets": "Улица",
			"metros": "Метро"
		}
	}
}
</i18n>