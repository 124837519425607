<script setup lang="ts">

const props = defineProps({
	modelValue: {
		type: Object,
		required: true
	},
	items: {
		type: Array,
		default: () => ([])
	},
	url: {
		type: String,
		default: undefined
	},
	searchField: {
		type: String,
		default: 'filter[title]'
	},
	labelField: {
		type: String,
		default: 'title',
	},
	valueField: {
		type: String,
		default: 'id'
	},
	query: {
		type: Object,
		default: () => ({})
	},
	sort: {
		type: String,
		default: 'title'
	},
	placeholder: {
		type: String,
		required: true
	},
	searchable: {
		type: Boolean,
		default: false
	},
	searchablePlaceholder: {
		type: String,
		default: undefined
	},
	multiple: {
		type: Boolean,
		default: true
	},
	isLoading: {
		type: Boolean,
		default: false
	},
	fullscreen: {
		type: Boolean,
		default: false
	},
	limit: {
		type: Number,
		default: 50
	},
	onChange: {
		type: Function,
		required: true
	},
	onClear: {
		type: Function,
		required: true
	}
})

const emits = defineEmits([
	'update:modelValue'
])

const villageStore = useVillageStore()
const buildingStore = useBuildingStore()

const localValue = computed({
	get: () => props.modelValue,
	set: (value) => emits('update:modelValue', value)
})

const { t } = useI18n({
	useScope: 'local'
})

const copiedValue = ref([ ...<[]>localValue.value ])

const isPending = computed(() => {
	return villageStore.pending || buildingStore.pending || props.isLoading
})

const isEmpty = computed(() => {
	return props.multiple
		? localValue.value.length === 0
		: Object.keys(props.modelValue).length === 0
})

const isOpen = ref(false)
const isConfirm = ref(false)

const onConfirm = () => {
	localValue.value = [ ...<[]>copiedValue.value ]
	isConfirm.value = true
	isOpen.value = false
}

const onDefault = () => {
	copiedValue.value = [ ...<[]>localValue.value ]
}

watch(localValue, (newVal: any) => {
	copiedValue.value = newVal
})

watch(isOpen, (newVal: boolean) => {
	if (!newVal && !isConfirm.value) {
		onDefault()
	}
	isConfirm.value = false
})

watch(copiedValue, async (newVal: any) => {
	props.onChange(newVal)
})
</script>

<template>
	<UiSelectMenuMultiple
		v-model="localValue"
		class="hidden md:block"
		:items="items"
		:url="url"
		:query="query"
		:search-field="searchField"
		:label-field="labelField"
		:valueField="valueField"
		:placeholder="placeholder"
		:searchable="searchable"
		:searchable-placeholder="searchablePlaceholder"
		:loading="isLoading"
		:on-change="onChange"
	/>
	<div
		v-bind="$attrs"
		class="block md:hidden"
	>
		<UButton
			color="gray"
			variant="outline"
			trailing-icon="i-heroicons-chevron-down-20-solid"
			class="w-fit max-w-80"
			:ui="{
				icon: {
					base: 'text-gray-400 dark:text-gray-500 ml-auto'
				}
			}"
			@click="isOpen = true"
			block
		>
			<div
				v-if="!isEmpty"
				class="flex gap-1"
			>
        <span class="flex max-w-80 truncate">
		      <template v-if="multiple">
			      <span>{{ localValue.map((v: any) => v.title)[0] }}</span>
			      <template v-if="localValue.length > 1">
				      <span class="w-[5ch] truncate">
					      , {{ localValue.map((v: any) => v.title)[1] }}
				      </span>
			      </template>
		      </template>
		      <template v-else>
			       {{ localValue.title }}
		      </template>
	      </span>
				<UBadge
					size="xs"
					color="gray"
				>
					{{ multiple ? localValue.length : 1 }}
				</UBadge>
      </div>
			<span v-else>
	      {{ placeholder }}
      </span>
		</UButton>
		<SearchFilterModal
			v-model="isOpen"
			:title="placeholder"
			:loading="villageStore.pending"
			:fullscreen="fullscreen"
			:on-confirm="onConfirm"
			:on-clear="onClear"
		>
			<div class="flex flex-col">
			<UiCommandPalette
				v-model="copiedValue"
				:items="items"
				:url="url"
				:query="query"
				:placeholder="placeholder"
				:searchable-placeholder="searchablePlaceholder"
				:field-label="labelField"
				:field-value="valueField"
				:loading="isLoading"
				:limit="limit"
			/>
			</div>
		</SearchFilterModal>
	</div>
</template>

<i18n lang="json">
{
	"ru": {
		"not_found": "По запросу «{query}» ничего не найдено",
		"empty": "Результатов нет"
	}
}
</i18n>