<script setup lang="ts">
const props = defineProps({
	modelValue: {
		type: Object,
		required: true
	},
	onChange: {
		type: Function,
		required: true
	},
	onClear: {
		type: Function,
		required: true
	}
})

const emits = defineEmits([
	'update:modelValue'
])

const { t: tg } = useI18n({
	useScope: 'global'
})

const { t } = useI18n({
	useScope: 'local'
})

const localValue = computed({
	get: () => props.modelValue,
	set: (value) => emits('update:modelValue', value)
})

const isLoading = ref(true)

const items = ref([])

const onLoad = async () => {
	isLoading.value = true

	const result = await useAPIFetch<{ data: any }>('/v1/objects/search/metro')

	isLoading.value = false

	items.value = result.data

	const tmp = []
	for (const item of items.value) {
		if (localValue.value.includes(item.title)) {
			tmp.push(item)
		}
	}
	if (tmp.length) {
		localValue.value = tmp
	}

}

onMounted(async () => {
	await onLoad()
})

</script>

<template>
	<SearchFilterSelectMenuMultiple
		v-model="localValue"
		:items="items"
		value-field="title"
		:is-loading="isLoading"
		:placeholder="tg('filter.field.metro')"
		:searchable="true"
		:searchable-placeholder="tg('filter.placeholder.metro')"
		:fullscreen="true"
		:on-change="onChange"
		:on-clear="onChange"
		:limit="300"
	/>
</template>