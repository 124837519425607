<script setup lang="ts">
import { watchDebounced } from '@vueuse/core'
import type { PropType } from 'vue'
import type { ListItem } from '~/types'
import { ModuleType, PathType, TagType } from '~/enums'

const props = defineProps({
	modelValue: {
		type: Object,
		required: true
	},
	isOpen: {
		type: Boolean,
		default: false
	},
	onClear: {
		type: Function,
		required: true
	},
	moduleType: {
		type: Number as PropType<ModuleType>,
		required: true
	},
	pathType: {
		type: undefined as PropType<PathType | undefined>,
		default: undefined
	},
	filterTypes: {
		type: Object as PropType<ListItem[]>,
		required: true
	},
})

const emits = defineEmits([
	'update:modelValue',
	'update:isOpen'
])

const regionStore = useRegionStore()
const tagStore = useTagStore()
const filterStore = useFilterStore()

const localValue = computed({
	get: () => props.modelValue,
	set: (value) => emits('update:modelValue', value)
})

const localIsOpen = computed({
	get: () => props.isOpen,
	set: (value) => emits('update:isOpen', value)
})

const { t: tg } = useI18n({
	useScope: 'global'
})

const { t } = useI18n({
	useScope: 'local'
})

const copiedValue = reactive(JSON.parse(JSON.stringify(localValue.value)))

const isVillage = computed(() => {
	if (props.moduleType === ModuleType.Village) {
		return true
	}
	return !!(props.moduleType === ModuleType.Object &&
		props.pathType &&
		[ PathType.Plot, PathType.Cottage, PathType.Townhouse, PathType.Duplex, PathType.House ].includes(props.pathType)
	)
})

const isBuilding = computed(() => {
	if (props.moduleType === ModuleType.Building) {
		return true
	}
	return props.moduleType === ModuleType.Object && props.pathType === PathType.Flat;
})

const isObjects = computed(() => !isVillage && !isBuilding)

const isOpen = ref(false)
const isConfirm = ref(false)

const onConfirm = () => {
	Object.keys(copiedValue).forEach((key) => {
		localValue.value[key] = JSON.parse(JSON.stringify(copiedValue[key]))
	})
	
	isConfirm.value = true
	isOpen.value = false
}

const onDefault = () => {
	Object.assign(copiedValue, JSON.parse(JSON.stringify(localValue.value)))
}

watch(localIsOpen, (newVal: any) => {
	if (newVal || (!newVal && !isConfirm.value)) {
		onDefault()
	}
	isConfirm.value = false
})

watchDebounced(copiedValue, async (newVal: any) => {
	if (filterStore.onCount) {
		await filterStore.onCount(newVal)
	}
}, {
	debounce: 100,
	maxWait: 1000
})
</script>

<template>
	<SearchFilterModal
		v-model="localIsOpen"
		:title="t('title')"
		:fullscreen="true"
		:loading="filterStore.getPending ? filterStore.getPending() : 0"
		:on-confirm="onConfirm"
		:on-clear="onClear"
	>
		<div class="flex flex-col gap-4 px-2 py-2">
			<SearchFilterAllItem
				:title="tg('filter.field.type')"
			>
				<UiCommandPalette
					v-model="copiedValue.type"
					:items="filterTypes"
					:multiple="false"
					field-label="label"
					field-value="value"
					:ui="{
						group: {
							wrapper: 'p-0'
						}
					}"
				/>
			</SearchFilterAllItem>
			<SearchFilterMoreItem
				v-if="isVillage"
				v-model="copiedValue.types"
				class="px-2"
				:title="tg('filter.field.types')"
				:items="tagStore.findByType(TagType.VillageType)"
			/>
			<SearchFilterAllItem
				v-if="isBuilding && false"
				:title="tg('filter.field.finish')"
			>
				<SearchFilterBuildingFinishInputWrapper
					v-if="isBuilding"
					v-model="copiedValue.finish"
					:placeholder="tg('filter.field.finish')"
				/>
			</SearchFilterAllItem>
			<SearchFilterAllItem
				v-if="isVillage"
				:title="tg('filter.field.area')"
			>
				<SearchFilterAreaInput
					v-model="copiedValue.area"
					class="px-0 py-0"
					:autofocus="false"
				/>
			</SearchFilterAllItem>
			<SearchFilterAllItem
				v-if="isBuilding"
				:title="tg('filter.field.area')"
			>
				<SearchFilterBuildingAreaInput
					v-model="copiedValue.area"
					class="px-0 py-0"
					:autofocus="false"
				/>
			</SearchFilterAllItem>
			<SearchFilterMoreItem
				v-model="copiedValue.class"
				class="px-2"
				:title="tg('filter.field.class')"
				:items="tagStore.findByType(isBuilding ? TagType.BuildingClass : TagType.VillageClass)"
			/>
			<SearchFilterAllItem
				v-if="false"
				:title="tg('filter.field.region')"
			>
				<UiCommandPalette
					v-model="copiedValue.region"
					:items="regionStore.getOptions"
					:multiple="false"
					field-label="label"
					field-value="value"
					:ui="{
						group: {
							wrapper: 'p-0'
						}
					}"
				/>
			</SearchFilterAllItem>
			<SearchFilterMoreItem
				v-if="isVillage"
				v-model="copiedValue.highway"
				class="px-2"
				:title="tg('filter.field.highway')"
				:items="tagStore.findByType(TagType.Highway)"
			/>
			<SearchFilterAllItem
				v-if="isVillage"
				:title="tg('filter.field.mkad')"
			>
				<UiRangeInput
					v-model="copiedValue.mkad"
					:postfix="tg('unit.km')"
					:autofocus="false"
				/>
			</SearchFilterAllItem>
			<SearchFilterAllItem
				:title="tg('filter.field.price')"
			>
				<UiRangeInput
					v-model="copiedValue.price"
					:postfix="tg('unit.currency.rub')"
					:autofocus="false"
				/>
			</SearchFilterAllItem>
			<SearchFilterMoreItem
				v-if="isVillage"
				v-model="copiedValue.purpose_lands"
				class="px-2"
				:title="tg('filter.field.purpose_lands')"
				:items="tagStore.findByType(TagType.PurposeLand)"
			/>
			<SearchFilterMoreItem
				v-if="isVillage"
				v-model="copiedValue.communications"
				class="px-2"
				:title="tg('filter.field.communications')"
				:items="tagStore.findByType(TagType.Communications)"
			/>
			<SearchFilterMoreItem
				v-if="isVillage"
				v-model="copiedValue.heating"
				class="px-2"
				:title="tg('filter.field.heating')"
				:items="tagStore.findByType(TagType.Heating)"
			/>
			<SearchFilterMoreItem
				v-if="isVillage"
				v-model="copiedValue.safety"
				class="px-2"
				:title="tg('filter.field.safety')"
				:items="tagStore.findByType(TagType.Safety)"
			/>
			<SearchFilterMoreItem
				v-model="copiedValue.infrastructures"
				class="px-2"
				:title="tg('filter.field.infrastructures')"
				:items="tagStore.findByType(isBuilding ? TagType.BuildingInfrastructure : TagType.Infrastructure)"
			/>
			<SearchFilterMoreItem
				v-if="isVillage"
				v-model="copiedValue.village_nears"
				class="px-2"
				:title="tg('filter.field.village_nears')"
				:items="tagStore.findByType(TagType.VillageNear)"
			/>
			<SearchFilterMoreItem
				v-if="isVillage"
				v-model="copiedValue.roads"
				class="px-2"
				:title="tg('filter.field.roads')"
				:items="tagStore.findByType(TagType.Road)"
			/>
			<SearchFilterMoreItem
				v-if="isVillage"
				v-model="copiedValue.transports"
				class="px-2"
				:title="tg('filter.field.transports')"
				:items="tagStore.findByType(TagType.Transport)"
			/>
			<SearchFilterMoreItem
				v-if="isObjects"
				v-model="copiedValue.materials"
				class="px-2"
				:title="tg('filter.field.materials')"
				:items="tagStore.findByType(TagType.HouseMaterial)"
			/>
			<SearchFilterMoreItem
				v-if="isObjects"
				v-model="copiedValue.interior_decorations"
				class="px-2"
				:title="tg('filter.field.interior_decorations')"
				:items="tagStore.findByType(TagType.InteriorDecoration)"
			/>
			<SearchFilterMoreItem
				v-if="isObjects"
				v-model="copiedValue.furniture"
				class="px-2"
				:title="tg('filter.field.furniture')"
				:items="tagStore.findByType(TagType.Furniture)"
			/>
			<SearchFilterMoreItem
				v-if="isBuilding"
				v-model="copiedValue.house_material_types"
				class="px-2"
				:title="tg('filter.field.house_material_type')"
				:items="tagStore.findByType(TagType.BuildingHouseMaterialType)"
			/>
			<SearchFilterMoreItem
				v-if="isBuilding"
				v-model="copiedValue.residential_statuses"
				class="px-2"
				:title="tg('filter.field.residential_status')"
				:items="tagStore.findByType(TagType.BuildingResidentialStatus)"
			/>
			<SearchFilterMoreItem
				v-if="isBuilding"
				v-model="copiedValue.security"
				class="px-2"
				:title="tg('filter.field.security')"
				:items="tagStore.findByType(TagType.BuildingSecurity)"
			/>
			<SearchFilterMoreItem
				v-if="isBuilding"
				v-model="copiedValue.parking"
				class="px-2"
				:title="tg('filter.field.parking')"
				:items="tagStore.findByType(TagType.BuildingParking)"
			/>
		</div>
	</SearchFilterModal>
</template>

<i18n lang="json">
{
	"ru": {
		"title": "Фильтры",
		"button": {
			"confirm": "Найдено {count}"
		}
	}
}
</i18n>