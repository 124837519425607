<script setup lang="ts">
import { watchDebounced } from '@vueuse/core'
import type { FilterAreaState } from "~/types";

const props = defineProps({
	modelValue: {
		type: Object as PropType<FilterAreaState>,
		required: true,
	},
	title: {
		type: String,
		required: true
	},
	onChange: {
		type: Function,
		required: true
	},
	onClear: {
		type: Function,
		required: true
	}
})

const emits = defineEmits([
	'update:modelValue'
])

const { t: tg } = useI18n({
	useScope: 'global'
})

const villageStore = useVillageStore()

const localValue = computed({
	get: () => props.modelValue as FilterAreaState,
	set: (value) => emits('update:modelValue', value)
})

const copiedValue = reactive(JSON.parse(JSON.stringify(localValue.value)))

const label = computed(() => {
	const result = []
	
	const items = [
		{ field: localValue.value.general, label: tg('filter.area.general'), unit: tg('unit.square_meter') },
		{ field: localValue.value.kitchen, label: tg('filter.area.kitchen'), unit: tg('unit.square_meter') },
		{ field: localValue.value.living, label: tg('filter.area.living'), unit: tg('unit.square_meter') },
	]

	for (const item of items) {
		const tmp = []
		for (const name of ['from', 'to']) {
			if (item.field[name]) {
				tmp.push(tg('filter.placeholder.' + name) + ' ' + item.field[name])
			}
		}
		if (tmp.length) {
			result.push(item.label + ' ' + tmp.join(' ') + item.unit)
		}
	}
	
	return result.length ? result.join(', ') : props.title
})

const isOpenPopover = ref(false)
const isOpenModal = ref(false)

const onConfirm = () => {
	Object.keys(localValue.value).forEach(key => {
		localValue.value[key].from = copiedValue[key].from
		localValue.value[key].to = copiedValue[key].to
	})
	
	isOpenPopover.value = false
	isOpenModal.value = false
}

watch(localValue, (newVal: any) => {
	Object.assign(copiedValue, newVal)
})

watchDebounced(copiedValue, async (newVal: any) => {
	props.onChange(newVal)
}, {
	debounce: 300,
	maxWait: 1000
})
</script>

<template>
	<UPopover
		v-model:open="isOpenPopover"
		class="hidden md:block"
		:popper="{ placement: 'bottom-start' }"
		:ui="{
			trigger: 'inline-block w-max'
		}"
	>
		<UButton
			color="white"
			:label="label"
			class="font-normal"
			trailing-icon="i-heroicons-chevron-down-20-solid text-gray-400"
		/>
		<template #panel>
			<SearchFilterBuildingAreaInput
				v-model="copiedValue"
				class="px-4 py-3"
			/>
			<UDivider />
			<div class="px-1 pb-1">
				<UButton
					size="xs"
					class="mt-1 w-full"
					:ui="{
						inline: 'block'
				  }"
					:disabled="villageStore.pending"
					@click="onConfirm"
				>
					<SearchFilterConfirm />
				</UButton>
			</div>
		</template>
	</UPopover>
	<div class="block md:hidden">
		<UButton
			color="gray"
			variant="outline"
			trailing-icon="i-heroicons-chevron-down-20-solid"
			class="w-fit"
			:ui="{
				icon: {
					base: 'text-gray-400 dark:text-gray-500'
				}
			}"
			@click="isOpenModal = true"
		>
			{{ label }}
		</UButton>
		<SearchFilterModal
			v-model="isOpenModal"
			:title="title"
			:on-confirm="onConfirm"
			:on-clear="onClear"
		>
			<SearchFilterBuildingAreaInput
				v-model="copiedValue"
				class="px-4 py-3"
			/>
		</SearchFilterModal>
	</div>
</template>

<i18n lang="json">
{
	"ru": {
		"button": {
			"confirm": "Применить"
		}
	}
}
</i18n>