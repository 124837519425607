<script setup lang="ts">
import {useBuildingFinish} from "~/composables/building/useBuildingFinish"

const props = defineProps({
	modelValue: {
		type: undefined as PropType<string | undefined>,
		required: true,
	}
})

const emits = defineEmits([
	'update:modelValue'
])

const localValue = computed({
	get: () => props.modelValue,
	set: (value) => emits('update:modelValue', value)
})

const { t: tg } = useI18n({
	useScope: 'global'
})

const buildingFinish = useBuildingFinish()
const value = ref(buildingFinish.getItem(localValue.value))

watch(value, (newValue) => {
	localValue.value = localValue.value === newValue.value ? undefined : newValue.value
})

</script>

<template>
	<div
		v-bind="$attrs"
	>
		<UCommandPalette
			v-model="value"
			command-attribute="label"
			command-value="value"
			by="value"
			:searchable="false"
			:autoselect="false"
			:groups="buildingFinish.groups"
			:ui="{
				wrapper: 'divide-none',
				group: {
           wrapper: 'p-0 py-1',
           label: 'text-sm'
         },
         input: {
           wrapper: 'relative flex items-start'
         }
			}"
			nullable
		/>
	</div>
</template>