<script setup lang="ts">
import { breakpointsTailwind, useBreakpoints, useScroll } from '@vueuse/core'
import { ModuleType, PathType, TagType } from '~/enums'
import type { PropType } from 'vue'

const props = defineProps({
	moduleType: {
		type: Number as PropType<ModuleType>,
		required: true
	},
	pathType: {
		type: undefined as PropType<PathType | undefined>,
		default: undefined
	},
	path: {
		type: String,
		required: true
	},
	showInstalled: {
		type: Boolean,
		default: true
	},
	showConfirmButton: {
		type: Boolean,
		default: false
	},
	changeHistory: {
		type: Boolean,
		default: true
	},
	useQuery: {
		type: Boolean,
		default: false
	},
	onConfirmButton: {
		type: Function,
		default: () => ({})
	},
	onConfirm: {
		type: Function,
		default: () => ({})
	},
	onChange: {
		type: Function,
		default: () => ({})
	}
})

const { t: tg } = useI18n({
	useScope: 'global'
})

const { t } = useI18n({
	useScope: 'local'
})

const router = useRouter()
const filterTypes = useFilterTypes()
const arrayHelper = useArrayHelper()
const tagStore = useTagStore()
const filterStore = useFilterStore()
const filter = useFilter()

const breakpoints = useBreakpoints(breakpointsTailwind)
const isDesktop = breakpoints.isGreaterOrEqual('md')

const isMoreOpen = ref(false)
const isAllOpen = ref(false)
const buttonAllOpacity = ref(100)

const itemsRef = ref<HTMLElement | null>(null)
const { x: itemsScrollX } = useScroll(itemsRef)

watch(itemsScrollX, () => {
	const max = 16
	buttonAllOpacity.value = (1 - (itemsScrollX.value > max ? max : itemsScrollX.value) / max)
})

const state = filterStore.state
const search = ref(state.search)
const searchQuery = ref(state.search_query)

const hasState = computed(() => {
	return state.types.length > 0
		|| state.class.length > 0
		|| state.rooms.length > 0
		|| state.highway.length > 0
		|| filter.hasRange(state.area.house)
		|| filter.hasRange(state.area.plot)
		|| filter.hasRange(state.area.general)
		|| filter.hasRange(state.area.kitchen)
		|| filter.hasRange(state.area.house)
		|| filter.hasRange(state.area.living)
		|| filter.hasRange(state.mkad)
		|| filter.hasRange(state.price)
		|| hasMoreState.value
})

const hasMoreState = computed(() => {
	return state.purpose_lands.length > 0
		|| state.communications.length > 0
		|| state.heating.length > 0
		|| state.safety.length > 0
		|| state.infrastructures.length > 0
		|| state.village_nears.length > 0
		|| state.roads.length > 0
		|| state.transports.length > 0
		|| state.interior_decorations.length > 0
		|| state.furniture.length > 0
		|| state.finish_types.length > 0
		|| state.house_material_types.length > 0
		|| state.residential_statuses.length > 0
		|| state.security.length > 0
		|| state.parking.length > 0
})

const isVillage = computed(() => {
	if (props.moduleType === ModuleType.Village) {
		return true
	}
	return !!(props.moduleType === ModuleType.Object &&
		props.pathType &&
		[ PathType.Plot, PathType.Cottage, PathType.Townhouse, PathType.Duplex, PathType.House, PathType.Objects ].includes(props.pathType)
	)
})

const isBuilding = computed(() => {
	if (props.moduleType === ModuleType.Building) {
		return true
	}
	return props.moduleType === ModuleType.Object && props.pathType === PathType.Flat;
})

const isShowArea = computed(() => state.types.length)

const hasClass = computed(() => isVillage.value || isBuilding.value)

const onConfirmState = async () => {
	filterStore.page = 1
	
	if (props.changeHistory) {
		const { fullUrl } = filter.makeUrl(state, props.path)

		await router.push(fullUrl)
		history.replaceState(history.state, null, fullUrl)
	}
	
	props.onConfirm(state)
}

const onChangeState = async (name: string, value: any) => {
	props.onChange({ ...state, ...{
		[name]: value
	}})
}

const onClearState = (path: string | undefined = undefined) => {
	if (path) {
		if (path === 'types') {
			arrayHelper.set(state, 'area', arrayHelper.get(filterStore.defaultState(), 'area'))
		}
		arrayHelper.set(state, path, arrayHelper.get(filterStore.defaultState(), path))
	}
	else {
		const defState = filterStore.defaultState()
		delete defState.region
		Object.assign(state, defState)
	}
}

const onAll = () => {
	isAllOpen.value = true
}

watch(state, () => {
	onConfirmState()
})

</script>

<template>
	<ClientOnly>
		<div
			v-bind="$attrs"
			class="relative z-40 bg-white max-md:w-[100svw]"
		>
			<div
				ref="itemsRef"
				class="flex w-full bg-transparent max-md:pl-1 md:bg-gray-200 md:rounded-lg md:border md:px-7 md:py-6 gap-6 overflow-x-auto md:overflow-inherit overscroll-x-contain scrollbar-hide"
			>
				<div
					class="md:hidden flex justify-end z-[20] sticky flex-[0_0_54px] left-0 -mr-[2rem]"
					:style="{
						'opacity': buttonAllOpacity,
					}"
				>
					<UButton
						variant="outline"
						icon="i-heroicons-funnel"
						class="bg-white mr-2.5"
						@click="onAll"
					/>
				</div>
				<div class="flex flex-col md:flex-1 gap-2.5">
					<div class="flex md:flex-wrap gap-2.5 pl-2.5 pr-4 w-max md:w-auto md:px-0">
						<SearchFilterTypeInput
							v-model="state.type"
							:options="filterTypes"
							:on-clear="onClearState"
						/>
						<SearchFilterSelectMenuMultiple
							v-if="isVillage"
							v-model="state.types"
							:items="tagStore.findByType(TagType.VillageType)"
							:placeholder="tg('filter.field.types')"
							:on-change="(val: any) => onChangeState('types', val)"
							:on-clear="() => onClearState('types')"
						/>
						<SearchFilterSelectMenuMultiple
							v-if="isBuilding"
							v-model="state.rooms"
							:items="tagStore.findByType(TagType.BuildingRoom)"
							:placeholder="tg('filter.field.rooms')"
							:on-change="(val: any) => onChangeState('rooms', val)"
							:on-clear="() => onClearState('rooms')"
						/>
						<SearchFilterBuildingFinishInputWrapper
							v-if="isBuilding"
							v-model="state.finish"
							:title="tg('filter.field.finish')"
							:on-change="(val: any) => onChangeState('finish', val)"
							:on-clear="onClearState"
						/>
						<SearchFilterAreaInputWrapper
							v-if="isVillage && isShowArea"
							v-model="state.area"
							:title="tg('filter.field.area')"
							:on-change="(val: any) => onChangeState('area', val)"
							:on-clear="onClearState"
						/>
						<SearchFilterBuildingAreaInputWrapper
							v-if="isBuilding"
							v-model="state.area"
							:title="tg('filter.field.area')"
							:on-change="(val: any) => onChangeState('area', val)"
							:on-clear="onClearState"
						/>
						<SearchFilterSelectMenuMultiple
							v-if="hasClass"
							v-model="state.class"
							:items="tagStore.findByType(isBuilding ? TagType.BuildingClass : TagType.VillageClass)"
							:placeholder="tg('filter.field.class')"
							:on-change="(val: any) => onChangeState('class', val)"
							:on-clear="() => onClearState('class')"
						/>
						<SearchFilterRegionInput
							v-if="isVillage"
							v-model="state.region"
							:on-clear="onClearState"
						/>
						<SearchFilterSelectMenuMultiple
							v-if="isVillage"
							v-model="state.highway"
							:items="tagStore.findByType(TagType.Highway)"
							:placeholder="tg('filter.field.highway')"
							:on-confirm="onConfirmState"
							:on-change="(val: any) => onChangeState('highway', val)"
							:on-clear="() => onClearState('highway')"
						/>
						<SearchFilterRangeInput
							v-if="isVillage"
							v-model="state.mkad"
							:title="tg('filter.field.mkad')"
							:unit="tg('unit.km')"
							:on-change="(val: any) => onChangeState('mkad', val)"
							:on-clear="onClearState"
						/>
						<SearchFilterMetrosInput
							v-if="isBuilding"
							v-model="state.metro"
							:on-change="(val: any) => onChangeState('metro', val)"
							:on-clear="() => onClearState('metro')"
						/>
						<SearchFilterRangeInput
							v-model="state.price"
							:title="tg('filter.field.price')"
							:unit="tg('unit.currency.rub')"
							:on-change="(val: any) => onChangeState('price', val)"
							:on-clear="onClearState"
						/>
					</div>
					<div class="hidden md:flex md:flex-wrap gap-2.5">
						<SearchFilterQueryInput
							v-if="useQuery"
							v-model="state.search_query"
							class="flex-1"
							:placeholder="isBuilding ? tg('filter.placeholder.search.building') : tg('filter.placeholder.search.village')"
						/>
						<SearchFilterInput
							v-else
							v-model="search"
							class="flex-1"
							:placeholder="isBuilding ? tg('filter.placeholder.search.building') : tg('filter.placeholder.search.village')"
						/>
					</div>
				</div>
				<div class="hidden md:flex flex-col gap-2 w-48 items-end">
					<div>
						<UChip
							size="lg"
							position="top-left"
							:show="hasMoreState"
							inset
						>
							<UButton
								variant="outline"
								leading-icon="i-heroicons-funnel"
								class="bg-white pl-4"
								@click="isMoreOpen = true"
							>
								{{ t('button.more_filters') }}
							</UButton>
						</UChip>
					</div>
					<UButton
						v-if="showConfirmButton"
						@click="onConfirmButton(filterStore.state)"
					>
						<SearchFilterConfirm
							text="filter.button.show"
						/>
					</UButton>
					<UButton
						v-else-if="hasState"
						variant="link"
						leading-icon="i-heroicons-x-mark"
						@click="onClearState()"
					>
						{{ t('button.reset') }}
					</UButton>
				</div>
			</div>
			<div class="block md:hidden pl-4 pr-4 mt-3">
				<SearchFilterQueryInput
					v-if="useQuery"
					v-model="state.search_query"
					class="flex-1"
					:placeholder="isBuilding ? tg('filter.placeholder.search.building') : tg('filter.placeholder.search.village')"
				/>
				<SearchFilterInput
					v-else
					v-model="state.search"
					class="flex-1"
					:placeholder="isBuilding ? tg('filter.placeholder.search.building') : tg('filter.placeholder.search.village')"
				/>
			</div>
			<SearchFilterInstalled
				v-if="hasState && showInstalled"
				v-model="state"
				class="order-4"
				:on-clear="onClearState"
			/>
			<SearchFilterMore
				v-if="isDesktop"
				v-model="state"
				v-model:is-open="isMoreOpen"
				:module-type="moduleType"
				:path-type="pathType"
			/>
			<SearchFilterAll
				v-if="!isDesktop"
				v-model="state"
				v-model:is-open="isAllOpen"
				:module-type="moduleType"
				:path-type="pathType"
				:filter-types="filterTypes"
				:on-clear="onClearState"
			/>
		</div>
	</ClientOnly>
</template>

<i18n lang="json">
{
	"ru": {
		"button": {
			"more_filters": "Еще фильтры",
			"reset": "Сбросить фильтры"
		}
	}
}
</i18n>