import { createSharedComposable } from '@vueuse/core'

const _useDateHelper = () => {
	const getYearsBetweenDates = (start: Date, end: Date): number => {
		let years = end.getFullYear() - start.getFullYear();
		
		if (end.getMonth() < start.getMonth() ||
			(end.getMonth() === start.getMonth() && end.getDate() < start.getDate())) {
			years--;
		}
		
		return years;
	}
	
	const getQuartersBetweenDates = (start: Date, end: Date): { quarter: number, year: number }[] => {
		const startQuarter = Math.floor(start.getMonth() / 3) + 1
		const endQuarter = Math.floor(end.getMonth() / 3) + 1
		
		const startYear = start.getFullYear()
		const endYear = end.getFullYear()
		
		if (endYear < startYear || (endYear === startYear && endQuarter < startQuarter)) {
			throw new Error("End date must be after start date.")
		}
		
		const quarters: { quarter: number, year: number }[] = []
		
		for (let q = startQuarter; q <= 4; q++) {
			quarters.push({ quarter: q, year: startYear })
		}
		
		for (let y = startYear + 1; y < endYear; y++) {
			for (let q = 1; q <= 4; q++) {
				quarters.push({ quarter: q, year: y })
			}
		}
		
		for (let q = 1; q <= endQuarter; q++) {
			quarters.push({ quarter: q, year: endYear })
		}
		
		return quarters;
	}
	
	const getRemainingQuarters = (start: Date, end: Date): { quarter: number, year: number }[] => {
		const startQuarter = Math.floor(start.getMonth() / 3) + 1
		const endQuarter = Math.floor(end.getMonth() / 3) + 1
		
		const startYear = start.getFullYear()
		const endYear = end.getFullYear()
		
		if (endYear < startYear || (endYear === startYear && endQuarter < startQuarter)) {
			throw new Error("End date must be after start date.")
		}
		
		const quarters: { quarter: number, year: number }[] = []
		
		for (let q = startQuarter + 1; q <= 4; q++) {
			quarters.push({ quarter: q, year: startYear })
		}
		
		for (let y = startYear + 1; y < endYear; y++) {
			for (let q = 1; q <= 4; q++) {
				quarters.push({ quarter: q, year: y })
			}
		}
		
		for (let q = 1; q <= endQuarter; q++) {
			quarters.push({ quarter: q, year: endYear })
		}
		
		return quarters;
	}
	
	const getEndOfYear = (date: Date): Date => {
		let endOfYear = new Date(date.getTime())
		endOfYear.setMonth(11, 31)
		endOfYear.setHours(23, 59, 59, 999)
		return endOfYear;
	}
	
	const addYears = (date: Date, years: number): Date => {
		const result = new Date(date)
		result.setFullYear(result.getFullYear() + years)
		return result
	}
	
	return {
		getYearsBetweenDates,
		getQuartersBetweenDates,
		getRemainingQuarters,
		getEndOfYear,
		addYears,
	}
}

export const useDateHelper = createSharedComposable(_useDateHelper)