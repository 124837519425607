<script setup lang="ts">
import { watchDebounced } from '@vueuse/core'
import {useBuildingFinish} from "~/composables/building/useBuildingFinish";

const props = defineProps({
	modelValue: {
		type: undefined as PropType<string | undefined>,
		required: true,
	},
	title: {
		type: String,
		required: true
	},
	onChange: {
		type: Function,
		required: true
	},
	onClear: {
		type: Function,
		required: true
	}
})

const emits = defineEmits([
	'update:modelValue'
])

const { t: tg } = useI18n({
	useScope: 'global'
})

const buildingStore = useBuildingStore()
const buildingFinish = useBuildingFinish()

const localValue = computed({
	get: () => props.modelValue,
	set: (value) => emits('update:modelValue', value)
})

const copiedValue = ref(localValue.value)

const label = computed(() => {
	if (localValue.value) {
		const item = buildingFinish.getItem(localValue.value)
		if (item) {
			return item.label_full
		}
	}
	
	return props.title
})

const isOpenPopover = ref(false)
const isOpenModal = ref(false)

const onConfirm = () => {
	isOpenPopover.value = false
	isOpenModal.value = false

	localValue.value = copiedValue.value
}

watch(localValue, (newVal: any) => {
	Object.assign(copiedValue, newVal)
})

watchDebounced(copiedValue, async (newVal: any) => {
	props.onChange(newVal)
}, {
	debounce: 300,
	maxWait: 1000
})
</script>

<template>
	<UPopover
		v-model:open="isOpenPopover"
		class="hidden md:block"
		:popper="{ placement: 'bottom-start' }"
		:ui="{
			trigger: 'inline-block w-max'
		}"
	>
		<UButton
			color="white"
			:label="label"
			class="font-normal"
			trailing-icon="i-heroicons-chevron-down-20-solid text-gray-400"
		/>
		<template #panel>
			<SearchFilterBuildingFinishInput
				v-model="copiedValue"
				class="px-2 py-1 overflow-y-auto max-h-[260px]"
			/>
			<UDivider />
			<div class="px-1 pb-1">
				<UButton
					size="xs"
					class="mt-1 w-full"
					:ui="{
						inline: 'block'
				  }"
					:disabled="buildingStore.pending"
					@click="onConfirm"
				>
					<SearchFilterConfirm />
				</UButton>
			</div>
		</template>
	</UPopover>
	<div class="block md:hidden">
		<UButton
			color="gray"
			variant="outline"
			trailing-icon="i-heroicons-chevron-down-20-solid"
			class="w-fit"
			:ui="{
				icon: {
					base: 'text-gray-400 dark:text-gray-500'
				}
			}"
			@click="isOpenModal = true"
		>
			{{ label }}
		</UButton>
		<SearchFilterModal
			v-model="isOpenModal"
			:title="title"
			:on-confirm="onConfirm"
			:on-clear="onClear"
		>
			<SearchFilterBuildingFinishInput
				v-model="copiedValue"
				class="px-4 py-3"
			/>
		</SearchFilterModal>
	</div>
</template>

<i18n lang="json">
{
	"ru": {
		"button": {
			"confirm": "Применить"
		}
	}
}
</i18n>