import { createSharedComposable } from "@vueuse/core";

const _useBuildingFinish = () => {
	const tg = useNuxtApp().$i18n.t
	
	const dateHelper = useDateHelper()
	
	const startDate = new Date()
	const endDate = dateHelper.getEndOfYear(dateHelper.addYears(startDate, 6))
	const quarters = dateHelper.getRemainingQuarters(startDate, endDate)
	
	const groups = [
		{
			key: 'key_completed',
			commands: [{
				label: tg('filter.building.completed'),
				label_full: tg('filter.building.completed'),
				value: 'completed'
			}]
		}
	]
	
	for (const quarter of quarters) {
		let group = groups.find((group: any) => group.key === quarter.year)
		if (!group) {
			group = {
				key: 'key_' + quarter.year,
				label: String(quarter.year),
				commands: []
			}
			groups.push(group)
		}
		group?.commands.push({
			label: tg('unit.quarter_num', quarter.quarter),
			label_full: tg('unit.quarter_num', quarter.quarter) + ' ' + quarter.year,
			value: quarter.quarter + '.' + quarter.year,
		})
	}
	
	const getItem = (value: string | undefined) => {
		if (value) {
			for (const group of groups) {
				const item = group.commands.find((c: any) => c.value === value)
				if (item) {
					return item
				}
			}
		}
		return undefined
	}
	
	return {
		groups,
		getItem,
	}
}

export const useBuildingFinish = createSharedComposable(_useBuildingFinish)