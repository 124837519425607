<script setup lang="ts">
import { breakpointsTailwind, useBreakpoints, watchDebounced } from '@vueuse/core'
import { ModuleType, PathType, TagType } from '~/enums'
import type {PropType} from "vue";
import type {FilterState} from "~/types";

const props = defineProps({
	modelValue: {
		type: Object,
		required: true
	},
	moduleType: {
		type: Number as PropType<ModuleType>,
		required: true
	},
	pathType: {
		type: undefined as PropType<PathType | undefined>,
		default: undefined
	},
	isOpen: {
		type: Boolean,
		default: false
	}
})

const emits = defineEmits([
	'update:modelValue',
	'update:isOpen'
])

const localValue = computed({
	get: () => props.modelValue as FilterState,
	set: (value) => emits('update:modelValue', value)
})

const localIsOpen = computed({
	get: () => props.isOpen,
	set: (value) => emits('update:isOpen', value)
})

const { t: tg } = useI18n({
	useScope: 'global'
})

const { t } = useI18n({
	useScope: 'local'
})

const breakpoints = useBreakpoints(breakpointsTailwind)
const isDesktop = breakpoints.isGreaterOrEqual('md')

const filterStore = useFilterStore()
const tagStore = useTagStore()

const isVillage = computed(() => {
	if (props.moduleType === ModuleType.Village) {
		return true
	}
	return !!(props.moduleType === ModuleType.Object &&
		props.pathType &&
		[ PathType.Plot, PathType.Cottage, PathType.Townhouse, PathType.Duplex, PathType.House ].includes(props.pathType)
	)
})

const isBuilding = computed(() => {
	if (props.moduleType === ModuleType.Building) {
		return true
	}
	return props.moduleType === ModuleType.Object && props.pathType === PathType.Flat;
})

const copiedValue = reactive({
	purpose_lands : [],
	communications : [],
	heating: [],
	safety : [],
	infrastructures : [],
	village_nears : [],
	roads : [],
	transports : [],
	materials: [],
	interior_decorations: [],
	furniture: [],
	finish_types: [],
	house_material_types: [],
	residential_statuses: [],
	security: [],
	parking: []
} as any)

const hasValues = computed(() => {
	return copiedValue.purpose_lands.length > 0
		|| copiedValue.communications.length > 0
		|| copiedValue.heating.length > 0
		|| copiedValue.safety.length > 0
		|| copiedValue.infrastructures.length > 0
		|| copiedValue.village_nears.length > 0
		|| copiedValue.roads.length > 0
		|| copiedValue.transports.length > 0
		|| copiedValue.materials.length > 0
		|| copiedValue.interior_decorations.length > 0
		|| copiedValue.furniture.length > 0
		|| copiedValue.finish_types.length > 0
		|| copiedValue.house_material_types.length > 0
		|| copiedValue.residential_statuses.length > 0
		|| copiedValue.security.length > 0
		|| copiedValue.parking.length > 0
})

const onDefault = () => {
	for (const key in copiedValue) {
		copiedValue[key] = [...localValue.value[key] ]
	}
}

const onReset = () => {
	for (const key in copiedValue) {
		copiedValue[key as keyof typeof copiedValue] = []
	}
}

const onClose = () => {
	localIsOpen.value = false
}

const onConfirm = () => {
	for (const key in copiedValue) {
		localValue.value[key] = [... copiedValue[key as keyof typeof copiedValue] ]
	}
	onClose()
}

const onClear = () => {
	onReset()
	onConfirm()
}

watch(localIsOpen, (newVal) => {
	newVal ? onDefault() : onReset()
})

watchDebounced(copiedValue, async (newVal: any) => {
	if (filterStore.onCount) {
		await filterStore.onCount({ ...localValue.value, ...newVal })
	}
}, {
	debounce: 300,
	maxWait: 1000
})
</script>

<template>
	<UModal
		v-model="localIsOpen"
		:fullscreen="!isDesktop"
		:ui="{
			width: 'w-full sm:max-w-2xl md:max-w-3xl lg:max-w-5xl'
		}"
	>
		<div class="flex flex-col gap-6 h-full">
			<div class="flex w-full pt-6 pl-6 pr-4">
				<div class="w-11"></div>
				<h3 class="flex-1 text-gray-600 text-2xl font-normal text-center">
					{{ t('title') }}
				</h3>
				<UButton
					variant="ghost"
					color="white"
					size="md"
					icon="i-heroicons-x-mark-16-solid"
					class="w-11 -mt-2 focus:ring-0"
					:ui="{
						icon: {
							base: '!w-7 !h-7 text-gray-500 hover:opacity-75 transition-opacity'
						}
					}"
					@click="onClose"
				/>
			</div>
			<div class="flex flex-col gap-4 px-6 flex-1 md:max-h-[540px] overflow-y-auto">
				<SearchFilterMoreItem
					v-if="isVillage"
					v-model="copiedValue.purpose_lands"
					:title="tg('filter.field.purpose_lands')"
					:items="tagStore.findByType(TagType.PurposeLand)"
				/>
				<SearchFilterMoreItem
					v-if="isVillage"
					v-model="copiedValue.communications"
					:title="tg('filter.field.communications')"
					:items="tagStore.findByType(TagType.Communications)"
				/>
<!--				<SearchFilterMoreItem-->
<!--					v-if="isVillage"-->
<!--					v-model="copiedValue.heating"-->
<!--					:title="tg('filter.field.heating')"-->
<!--					:items="tagStore.findByType(TagType.Heating)"-->
<!--				/>-->
				<SearchFilterMoreItem
					v-if="isVillage"
					v-model="copiedValue.safety"
					:title="tg('filter.field.safety')"
					:items="tagStore.findByType(TagType.Safety)"
				/>
				<SearchFilterMoreItem
					v-model="copiedValue.infrastructures"
					:title="tg('filter.field.infrastructures')"
					:items="tagStore.findByType(isBuilding ? TagType.BuildingInfrastructure : TagType.Infrastructure)"
				/>
				<SearchFilterMoreItem
					v-if="isVillage"
					v-model="copiedValue.village_nears"
					:title="tg('filter.field.village_nears')"
					:items="tagStore.findByType(TagType.VillageNear)"
				/>
				<SearchFilterMoreItem
					v-if="isVillage"
					v-model="copiedValue.roads"
					:title="tg('filter.field.roads')"
					:items="tagStore.findByType(TagType.Road)"
				/>
				<SearchFilterMoreItem
					v-if="isVillage"
					v-model="copiedValue.transports"
					:title="tg('filter.field.transports')"
					:items="tagStore.findByType(TagType.Transport)"
				/>
				<SearchFilterMoreItem
					v-if="isVillage"
					v-model="copiedValue.materials"
					:title="tg('filter.field.materials')"
					:items="tagStore.findByType(TagType.HouseMaterial)"
				/>
				<SearchFilterMoreItem
					v-if="isVillage"
					v-model="copiedValue.interior_decorations"
					:title="tg('filter.field.interior_decorations')"
					:items="tagStore.findByType(TagType.InteriorDecoration)"
				/>
				<SearchFilterMoreItem
					v-if="isVillage"
					v-model="copiedValue.furniture"
					:title="tg('filter.field.furniture')"
					:items="tagStore.findByType(TagType.Furniture)"
				/>
				<SearchFilterMoreItem
					v-if="isBuilding"
					v-model="copiedValue.finish_types"
					:title="tg('filter.field.finish_type')"
					:items="tagStore.findByType(TagType.BuildingFinishType)"
				/>
				<SearchFilterMoreItem
					v-if="isBuilding"
					v-model="copiedValue.house_material_types"
					:title="tg('filter.field.house_material_type')"
					:items="tagStore.findByType(TagType.BuildingHouseMaterialType)"
				/>
				<SearchFilterMoreItem
					v-if="isBuilding"
					v-model="copiedValue.residential_statuses"
					:title="tg('filter.field.residential_status')"
					:items="tagStore.findByType(TagType.BuildingResidentialStatus)"
				/>
				<SearchFilterMoreItem
					v-if="isBuilding"
					v-model="copiedValue.security"
					:title="tg('filter.field.security')"
					:items="tagStore.findByType(TagType.BuildingSecurity)"
				/>
				<SearchFilterMoreItem
					v-if="isBuilding"
					v-model="copiedValue.parking"
					:title="tg('filter.field.parking')"
					:items="tagStore.findByType(TagType.BuildingParking)"
				/>
			</div>
			<div class="flex flex-col md:flex-row justify-center items-center px-6 pb-6 gap-1">
				<div class="w-48"></div>
				<div class="flex-1 flex justify-center">
					<UButton
						:disabled="filterStore.getPending ? filterStore.getPending() : false"
						@click="onConfirm"
					>
						<SearchFilterConfirm />
					</UButton>
				</div>
				<div class="flex w-48 justify-end">
					<UButton
						variant="link"
						leading-icon="i-heroicons-x-mark-16-solid"
						:disabled="!hasValues"
						@click="onClear"
					>
						{{ t('button.clear') }}
					</UButton>
				</div>
			</div>
		</div>
	</UModal>
</template>

<i18n lang="json">
{
	"ru": {
		"title": "Еще фильтры",
		"button": {
			"clear": "Сбросить"
		}
	}
}
</i18n>